import React from "react"
import styled from "styled-components"
import { v4 } from "uuid"
import RoofCard from "../../components/RoofCard"
import BreakpointUp from "../../components/Media/BreakpointUp"

const RoofType = styled.section`
  padding: 60px 0 0;
  ${BreakpointUp.lg`
        margin-top:-100px;
        padding:0;
    `}
`
const CardHorizontal = styled.div`
  display: flex;
  flex-wrap: wrap;
`
const CardHorizontalItem = styled.div`
  position: relative;
  width: 100%;
  ${BreakpointUp.lg`
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    `}
  &:nth-child(2) {
    .card {
      background-color: #0f0f11;
      color: #fff;
      border: none;
      box-shadow: none;
      &:after,
      &:before {
        display: none;
        ${BreakpointUp.lg`       
                    dislay:block;
                    background-color:#0F0F11;
                    content: '';
                    position: absolute;
                    left: 0;
                    right: 0;
                    height: 20px;
                    z-index: 1;
                `}
      }
      &:before {
        top: -18px;
      }
      &:after {
        bottom: -18px;
      }
    }
  }
`

const ProductRoofType = ({ data, intl }) => {
  return (
    <RoofType>
      <div className="container">
        <CardHorizontal>
          {data.map(item => (
            <CardHorizontalItem key={v4()}>
              <RoofCard data={item} intl={intl} />
            </CardHorizontalItem>
          ))}
        </CardHorizontal>
      </div>
    </RoofType>
  )
}

export default ProductRoofType
