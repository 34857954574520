import React, { useState, useEffect } from "react"
import { Link, graphql } from "gatsby"
import styled from "styled-components"
import { injectIntl } from "gatsby-plugin-intl"

import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  Section,
  BreadCrumb,
  MainPanel,
  LeftPannel,
  RightPannel,
  MarkdownContent,
} from "../components/Section"
import { BulletIcon } from "../components/Icons"
import QuotePopup from "../components/QuotePopup"
import TopHeader from "../components/TopHeader"
import ProductRoofTypeSection from "../sections/Product/ProductRoofTypeSection"
import ProductRoofSection from "../sections/Product/ProductRoofSection"
import ProductForm from "../sections/Product/ProductForm"
import ProductRoofStyleSection from "../sections/Product/ProductRoofStyleSection"
const TopHeaderCategory = styled.section`
  section {
    &:first-child {
      @media (min-width: 992px) {
        padding-bottom: 160px;
      }
    }
  }
`

function ProductCategoryPage({ data, intl }) {
  const pageData = data.contentfulProductCategoryLanding
  const [isVisibleQuote, setQuoteVisible] = useState(false)
  const [isSticky, setSticky] = useState(false)
  if (typeof window !== `undefined`) {
    if (isVisibleQuote === true) {
      document.body.classList.add("modal-open")
    } else {
      document.body.classList.remove("modal-open")
    }
  }
  useEffect(() => {
    const pageStartPoint = document
      .getElementById("page-start")
      .getBoundingClientRect().top
    const startOffset = document
      .getElementById("sticky-top")
      .getBoundingClientRect().top
    const endOffsset = document
      .getElementById("sticky-end")
      .getBoundingClientRect().top
    const handleScroll = () => {
      const currentScrollY = window.scrollY
      if (
        startOffset - pageStartPoint < currentScrollY &&
        currentScrollY < endOffsset - pageStartPoint
      ) {
        setSticky(true)
      } else {
        setSticky(false)
      }
    }
    window.addEventListener("scroll", handleScroll, { passive: true })
    return () => window.removeEventListener("scroll", handleScroll)
  }, [])
  const productSectionData = {
    title: pageData.productSectionTitle,
    description: pageData.productSectionDescription,
    products: data.allContentfulProduct.edges,
  }
  const roofstylesData = {
    title: pageData.roofSectionTitle,
    description: pageData.roofSectionDescription,
    roofStyles: pageData.roofStyles,
  }

  return (
    <Layout>
      <SEO title={pageData.metaTitle} description={pageData.metaDescription} />
      <div id="page-start" />
      <BreadCrumb>
        <div className="container">
          <Link to="/">
            {intl.formatMessage({ id: "Home" })} <BulletIcon />
          </Link>
          <span>{pageData.category.name}</span>
        </div>
      </BreadCrumb>
      <TopHeaderCategory>
        <TopHeader
          topHeaderTitle={pageData.heroTitle}
          topHeaderText={pageData.heroDescription}
          topHeaderImg={pageData.heroImage}
        />
        <ProductRoofTypeSection intl={intl} data={pageData.subcategoriesBar} />
      </TopHeaderCategory>
      <ProductRoofSection
        intl={intl}
        data={productSectionData}
        openQuoteModal={() => setQuoteVisible(true)}
      />
      <ProductRoofStyleSection intl={intl} data={roofstylesData} />
      <Section pt="90px" pb="90px" xpt="60px" xpb="60px" bgColor="#F9F9F9">
        <div className="container">
          <MainPanel id="sticky-top">
            <LeftPannel>
              <MarkdownContent>
                <div
                  dangerouslySetInnerHTML={{
                    __html: pageData.content.childMarkdownRemark.html,
                  }}
                />
              </MarkdownContent>
            </LeftPannel>
            <RightPannel>
              <div className={isSticky ? "sticky-wrapper" : "no-sticky"}>
                <ProductForm intl={intl} />
              </div>
            </RightPannel>
          </MainPanel>
        </div>
        <div id="sticky-end"></div>
      </Section>
      <QuotePopup
        isVisible={isVisibleQuote}
        onClose={() => setQuoteVisible(false)}
        intl={intl}
      />
    </Layout>
  )
}

export default injectIntl(ProductCategoryPage)

export const query = graphql`
  query ProductCategoryPageQuery(
    $slug: String
    $locale: String
    $category: String
  ) {
    contentfulProductCategoryLanding(
      slug: { eq: $slug }
      node_locale: { eq: $locale }
    ) {
      node_locale
      metaTitle
      metaDescription
      category {
        name
      }
      heroTitle
      heroDescription {
        childMarkdownRemark {
          html
        }
      }
      heroImage {
        fluid {
          aspectRatio
          base64
          sizes
          src
          srcSet
          srcSetWebp
          srcWebp
        }
        description
      }
      subcategoriesBar {
        ... on ContentfulProductSubCategoryLanding {
          id
          title
          slug
          heroImage {
            fluid {
              aspectRatio
              base64
              sizes
              src
              srcSet
              srcSetWebp
              srcWebp
            }
            description
          }
        }
      }
      productSectionTitle
      productSectionDescription {
        childMarkdownRemark {
          html
        }
      }
      roofSectionTitle
      roofSectionDescription {
        childMarkdownRemark {
          html
        }
      }
      roofStyles {
        title
        description {
          childMarkdownRemark {
            html
          }
        }
        image {
          fluid {
            aspectRatio
            base64
            sizes
            src
            srcSet
            srcSetWebp
            srcWebp
          }
          description
        }
        buttonText
        buttonLink
      }
      content {
        childMarkdownRemark {
          html
        }
      }
    }
    allContentfulProduct(
      filter: {
        productCategory: { name: { eq: $category } }
        node_locale: { eq: $locale }
      }
    ) {
      edges {
        node {
          url
          node_locale
          productSku
          productName
          productDescription {
            childMarkdownRemark {
              html
            }
          }
          featuredProduct
          productCategory {
            name
          }
          productSubcategory {
            name
          }
          productRoof {
            name
          }
          image {
            fluid(quality: 100) {
              aspectRatio
              base64
              sizes
              src
              srcSet
              srcSetWebp
              srcWebp
            }
            description
          }
          width
          length
          height
          startingPrice
          rto
          financing
          otherDetails {
            childMarkdownRemark {
              html
            }
          }
          freeDelivery
        }
      }
    }
  }
`
