import React from 'react'
import styled from 'styled-components'
import Image from "../../components/image"
import RequestForm from "../../components/RequestForm"
import {GetQuoteIcon} from "../../components/Icons"
import BreakpointUp from "../../components/Media/BreakpointUp"

const BackgroundImage = styled.div`
	display: block;
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0px;
	left:0;
	&:before{
		background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(4,4,4,1) 100%);
        background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(4,4,4,1) 100%);
        background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(4,4,4,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6040404',GradientType=0 );
		content:'';
		position:absolute;
		left:0;
		top:0;
		width:100%;
        height:100%;
        z-index:1;
	}
	.gatsby-image-wrapper {
		width: 100%;
		height: 100%;
	}
`
const CardForm = styled.div`
    position: relative;
    margin-bottom:30px;
    display:flex;
    flex-wrap:wrap;
    background: #fff;
    border:1px solid #ddd;
    width:100%;	
    box-shadow: 15px 15px 0px 0px rgba(0,0,0,0.05);
    z-index: 1;
`
const CardHeading = styled.div`
    position:relative;
    width:100%;
    padding:40px 20px 20px;
    ${BreakpointUp.sm`				
        padding:50px 30px 30px;        
    `}	
`
const CardTitle = styled.div`    
    color:#fff;
    display:flex;
    flex-wrap:nowrap;
    align-items:center; 
    position:relative;
    z-index:2;   
    margin-bottom:0;
    font-size: 22px;
    line-height: 26px;
    font-family: 'Rajdhani', sans-serif;
    font-weight:700;
    ${BreakpointUp.sm`				
        font-size:26px;
        line-height:30px;
    `}	
    .icon{
        width:48px;
        height:48px;
        min-width:48px;       
        display:inline-flex;
        align-items:center;
        justify-content:center;
        background: #f4f4f4;
        background: -moz-linear-gradient(top,  #f4f4f4 0%, #f4f4f4 50%, #dfdfdf 50%, #dfdfdf 100%);
        background: -webkit-linear-gradient(top,  #f4f4f4 0%,#f4f4f4 50%,#dfdfdf 50%,#dfdfdf 100%);
        background: linear-gradient(to bottom,#f4f4f4 0%,#f4f4f4 50%,#dfdfdf 50%,#dfdfdf 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f4f4f4', endColorstr='#dfdfdf',GradientType=0 );        
        svg{
            width:24px;
            height:24px;
        }
        + .text{
            margin-left:20px 
        }
    }
    .text{
        display:inline-flex;
        max-width: 330px;
        width: 100%;
    }
	
`
const CardBody = styled.div`
    padding:20px;
    width:100%;	
    ${BreakpointUp.sm`				
        padding:30px;
    `}
    & .form-action{
        & .btn{
        }
    }

`

const FormCard = ({ intl }) => {
	return(
		<CardForm className="card">
            <CardHeading>
                <BackgroundImage><Image name="cta-banner.jpg" alt="cta"/></BackgroundImage>
				<CardTitle>
                    <span className="icon">
                        <GetQuoteIcon />
                    </span>
                    <span className="text">{intl.formatMessage({ id: "To Request A Quote, Fill Out Our Short Form." })}</span>
                </CardTitle>
            </CardHeading>
			<CardBody><RequestForm intl={intl} /></CardBody>
		</CardForm> 		
	)	
}

export default FormCard