import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { Link } from "gatsby"
import { ArrowRightIcon } from "../Icons"
import BreakpointUp from "../Media/BreakpointUp"
import DefaultButton from "../Button/DefaultButton"

const CardWhite = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: #fff;
  border: 1px solid #e3e3e3;
  position: relative;
  box-shadow: 0px 3px 20px rgba(14, 20, 28, 0.2);
  color: #211f28;
  padding: 20px 10px;
  margin-bottom: 20px;
`
const CardWhiteTitle = styled.div`
  font-family: "Rajdhani", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 15px;
  ${BreakpointUp.md`    
        font-size: 20px;
        line-height: 26px;
    `}
  ${BreakpointUp.xl`
        font-size: 26px;
        line-height: 30px;
    `}
`

const CardFigure = styled.figure`
  position: relative;
  width: 100%;
  padding: 0 10px;
  margin-bottom: 0;
  flex: 0 0 50%;
  max-width: 50%;
  ${BreakpointUp.xl`
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
    `}
  .gatsby-image-wrapper {
    width: 100%;
  }
`
const CardWhiteBody = styled.div`
  position: relative;
  width: 100%;
  padding: 0 10px;
  flex: 0 0 50%;
  max-width: 50%;
  ${BreakpointUp.xl`   	
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    `}
`
const RoofCard = props => {
  const { data, intl } = props
  return (
    <CardWhite className="card">
      <CardFigure>
        <Img fluid={data.heroImage.fluid} alt={data.heroImage.description} />
      </CardFigure>
      <CardWhiteBody className="card-body">
        <CardWhiteTitle>{data.title}</CardWhiteTitle>
        <Link to={`/${intl.locale}${data.slug}`}>
          <DefaultButton
            icon={<ArrowRightIcon />}
            text={intl.formatMessage({ id: "Explore" })}
          />
        </Link>
      </CardWhiteBody>
    </CardWhite>
  )
}

export default RoofCard
