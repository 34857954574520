import React, { useState } from "react"
import styled from "styled-components"
import { ArrowRightIcon } from "../Icons"
import ProductCardList from "../ProductCardList"
import BreakpointUp from "../Media/BreakpointUp"
import BreakpointDown from "../Media/BreakpointDown"

const SectionGrid = styled.div`
  position: relative;
  max-width: 1220px;
  width: 100%;
  margin: auto;
`
const TabNavWrap = styled.div`
  position: relative;
  margin-bottom: 30px;
  ${BreakpointUp.md`
    margin-bottom:100px;
  `}
  &:before {
    ${BreakpointUp.md`
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      background-image: linear-gradient(to right,#707070 50%,#fff 0%);
      background-position: 0 0;
      background-size: 9px 1px;
      background-repeat: repeat-x;
      width: 100%;
      height: 1px;
    `}
  }
`
const TabNav = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  justify-content: center;
  max-width: 844px;
  width: 100%;
  margin: 0 auto;
`
const TabItem = styled.div`
  padding: 0 10px;
  flex: 0 0 50%;
  max-width: 50%;
  margin-bottom: 20px;
  .icon {
    opacity: 0;
    visibility: hidden;
  }
  .icon {
    ${BreakpointDown.md`
    display:none;
    `}
  }

  ${BreakpointUp.md`
    margin-bottom:0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  `}
  &.active {
    & button {
      ${BreakpointUp.md`
        padding-bottom:40px;
      `}
      border-color: #211F28;
      background: #211f28;
      color: #fff;
      .icon {
        opacity: 1;
        visibility: visible;
      }
    }
  }
`
const TabIcon = styled.span`
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
  background-color: #cfcfcf;
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  margin-bottom: -28px;
  &:before {
    content: "";
    position: absolute;
    background-color: #fff;
    width: 48px;
    height: 48px;
    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
  }
  & svg {
    fill: #211f28;
    transform: rotate(90deg);
  }
`
const TabText = styled.span`
  display: block;
`

const TabLink = styled.button`
  color: #b1b1b1;
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: "Rajdhani", sans-serif;
  background-color: #f9f9f9;
  border: 1px solid #e3e3e3;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  padding: 10px;
  ${BreakpointUp.md`
    font-size:18px;
  `}
  ${BreakpointUp.lg`
    font-size:20px;
    padding: 20px;
  `}
  &:focus {
    outline: none;
  }
  &:hover {
    border-color: #211f28;
    background: #211f28;
    color: #fff;
  }
`

const TabContent = styled.div`
  margin: 0;
`
const TabPane = styled.div`
  margin: 0;
  outline: none;
`
const TabProductCardList = ({ intl, data, openQuoteModal }) => {
  const [productData, setProductData] = useState({
    roof: "all",
    data: data,
  })
  let verticalRoofProduct = []
  let aframeRoofProduct = []
  let regularRoofProduct = []
  data.forEach(item => {
    if (item.node.productRoof?.name.indexOf("Vertical") > -1) {
      verticalRoofProduct.push(item)
    } else if (item.node.productRoof?.name.indexOf("A-Frame") > -1) {
      aframeRoofProduct.push(item)
    } else if (item.node.productRoof?.name.indexOf("Regular") > -1) {
      regularRoofProduct.push(item)
    } else {
      return true
    }
  })
  return (
    <SectionGrid>
      <TabNavWrap>
        <TabNav>
          <TabItem className={productData.roof === "all" ? "active" : ""}>
            <TabLink
              onClick={() => setProductData({ roof: "all", data: data })}
            >
              <TabText>{intl.formatMessage({ id: "All Roof" })}</TabText>
              <TabIcon className="icon">
                <ArrowRightIcon />
              </TabIcon>
            </TabLink>
          </TabItem>
          <TabItem className={productData.roof === "Vertical" ? "active" : ""}>
            <TabLink
              onClick={() =>
                setProductData({ roof: "Vertical", data: verticalRoofProduct })
              }
            >
              <TabText>{intl.formatMessage({ id: "Vertical Roof" })}</TabText>
              <TabIcon className="icon">
                <ArrowRightIcon />
              </TabIcon>
            </TabLink>
          </TabItem>
          <TabItem className={productData.roof === "A-Frame" ? "active" : ""}>
            <TabLink
              onClick={() =>
                setProductData({ roof: "A-Frame", data: aframeRoofProduct })
              }
            >
              <TabText>{intl.formatMessage({ id: "A-Frame Roof" })}</TabText>
              <TabIcon className="icon">
                <ArrowRightIcon />
              </TabIcon>
            </TabLink>
          </TabItem>
          <TabItem className={productData.roof === "Regular" ? "active" : ""}>
            <TabLink
              onClick={() =>
                setProductData({ roof: "Regular", data: regularRoofProduct })
              }
            >
              <TabText>{intl.formatMessage({ id: "Regular Roof" })}</TabText>
              <TabIcon className="icon">
                <ArrowRightIcon />
              </TabIcon>
            </TabLink>
          </TabItem>
        </TabNav>
      </TabNavWrap>

      <TabContent>
        <TabPane>
          <ProductCardList
            intl={intl}
            data={productData.data}
            openQuoteModal={openQuoteModal}
          />
        </TabPane>
      </TabContent>
    </SectionGrid>
  )
}

export default TabProductCardList
