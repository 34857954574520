import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Img from "gatsby-image"
import {
  PhoneIcon,
  GetQuoteIcon,
  WidthIcon,
  LenghtIcon,
  HeightIcon,
  AvailableIcon,
} from "../Icons"
import PrimaryIconButton from "../Button/PrimaryIconButton"
import DefaultButton from "../Button/DefaultButton"
import BreakpointUp from "../Media/BreakpointUp"
import BreakpointDown from "../Media/BreakpointDown"
import SkuBg from "../../images/sku-bg.svg"

const CardDefault = styled.div`
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #e3e3e3;
  background-color: #fff;
  text-align: left;
  position: relative;
  margin-bottom: 35px;
  box-shadow: 0px 3px 20px rgba(14, 20, 28, 0.1);
  transition: 0.3s all;
  // &:hover{
  //     box-shadow: 0px 5px 28px 2px rgba(14,20,28,.25);
  //     transform: translatey(-10px);
  // }
  ${BreakpointUp.sm`		
        margin-bottom:50px;        
	`}
  &:before {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    clip-path: polygon(0 0, 100% 0, 100% 100%);
    background-color: #f9f9f9;
    width: 50%;
    height: 50%;
    ${BreakpointUp.sm`		
            width: 37%;
            height: 37%;
        `}
  }
`
const CardDefaultTitle = styled.h3`
  margin: 0 0 20px;
  color: #6a6a6a;
  font-size: 22px;
  line-height: 28px;
  ${BreakpointUp.lg`    
        font-size:26px;
        line-height:34px;
    `}
  a {
    color: #6a6a6a;
    &:hover,
    &:focus {
      color: #211f28;
    }
  }
`
const CardDefaultFigure = styled.div`
  position: relative;
  width: 100%;
  margin: 30px 15px 15px;
  ${BreakpointUp.sm`		
        margin:50px 20px 20px;
	`}
  & .gatsby-image-wrapper {
    height: 100%;
  }
`

const Overlay = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 80%;
  display: flex;
  align-items: flex-end;
  padding: 10px;
  ${BreakpointUp.sm`		
        padding: 20px;
    `}
  strong {
    font-family: "Rajdhani", sans-serif;
    color: #fff;
    font-weight: 700;
    z-index: 1;
    position: relative;
    font-size: 22px;
    line-height: 24px;
    ${BreakpointUp.sm`		
            font-size:34px;
            line-height:44px;
        `}
  }
  &:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    opacity: 0.67;
    background: transparent linear-gradient(179deg, #191c1d00 0%, #040404 100%)
      0% 0% no-repeat padding-box;
  }
`

const SkuBadge = styled.div`
  font-family: "Rajdhani", sans-serif;
  background-image: ${props => props.bg};
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  text-align: center;
  color: #fff;
  top: -12px;
  left: -8px;
  padding: 12px 0;
  width: 310px;
  height: 56px;
  ${BreakpointDown.sm`
        font-size: 13px;	
        top: -10px;
        left: 0;
        padding: 5px 0px;
        width: 210px;
        height: 40px;
        background-size: cover;        
    `}
  strong {
    font-weight: 700;
    font-size: 13px;
    ${BreakpointUp.sm`		
            font-size:16px;            
        `}
  }
`

const CardDefaultBody = styled.div`
  position: relative;
  width: 100%;
  padding: 20px 15px 15px;
  ${BreakpointUp.sm`		
        padding: 20px 40px;  
	`}
  ${BreakpointUp.xl`		
        padding: 20px 40px;  
	`}
`

const ProductSize = styled.ul`
  display: flex;
  align-items: center;
  margin: 0 -5px 20px -5px;
  justify-content: space-between;
  ${BreakpointUp.sm`		
        margin:0 -10px 40px -10px;   
        justify-content: initial;     
    `}
  ${BreakpointUp.xl`		
        margin:0 -20px 40px -20px;        
    `}
`
const ProductItemSize = styled.li`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 5px;
  ${BreakpointUp.sm`	
        padding:0 10px;
    `}
  ${BreakpointUp.xl`		
        padding:0 20px;
    `}
	.icon {
    display: inline-flex;
    svg {
      width: 40px;
      ${BreakpointUp.sm`		
            width:auto;
            `}
    }
    + .text {
      margin-left: 5px;
      ${BreakpointUp.lg`		
                margin-left:10px;                
            `}
    }
  }
  .text {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    small {
      color: #6a6a6a;
      white-space: nowrap;
      font-size: 12px;
      line-height: 18px;
      ${BreakpointUp.sm`		
                font-size:14px;              
            `}
    }
    strong {
      display: block;
      font-family: "Rajdhani", sans-serif;
      font-weight: 700;
      color: #211f28;
      font-size: 20px;
      line-height: 24px;
      ${BreakpointUp.sm`		
                font-size:26px;
            `}
    }
  }
`

const CardDefaultFooter = styled.div`
  background-color: #f9f9f9;
  width: 100%;
  position: relative;
  padding: 0 15px 15px 15px;
  ${BreakpointUp.xl`		
        padding:0 15px 15px 40px;
	`}
  &:after {
    content: "";
    position: absolute;
    right: 15px;
    top: 0;
    background-image: linear-gradient(to right, #707070 50%, #fff 0%);
    background-position: 0 0;
    background-size: 9px 1px;
    background-repeat: repeat-x;
    width: 38%;
    height: 1px;
  }
`
const ButtonToolbar = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin: -20px 0 10px;
  z-index: 1;
  a {
    padding: 0 5px;
    ${BreakpointUp.sm`    
            padding:0 10px;
        `}
  }
  .btn {
    ${BreakpointDown.sm`    
            font-size:14px;  
        `}
    .icon {
      > svg {
        ${BreakpointDown.sm`    
                    width:14px;
                `}
      }
    }
  }
`
const HelpBlock = styled.div`
  position: relative;
  color: #6a6a6a;
  line-height: 26px;
  font-size: 10px;
  ${BreakpointUp.sm`    
        font-size:12px;
    `}
`
const FinanceOption = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-family: "Rajdhani", sans-serif;
  line-height: 20px;
  color: #1b1a21;
  font-weight: 600;
  right: 15px;
  top: 5px;
  margin: 0 -5px;
  ${BreakpointUp.sm`    
        right:15px;
        top:15px;
        margin:0 -15px;
    `}
`
const FinanceItem = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 5px;
  ${BreakpointUp.sm` 
        padding:0 15px;        
    `}
  .icon {
    > svg {
      fill: #52a400;
      ${BreakpointDown.sm` 
                font-size:14x;
            `}
    }
    + .text {
      margin-left: 6px;
    }
  }
  .text {
    font-size: 12px;
    line-height: 18px;
    ${BreakpointUp.sm`  
            line-height:20px;               
            font-size:16px;
        `}
  }
`
const ProductCard = ({ intl, data, openQuoteModal }) => {
  return (
    <CardDefault className="card">
      <SkuBadge className="sku" bg={`url(${SkuBg})`}>
        SKU : <strong>{data.productSku}</strong>
      </SkuBadge>
      <FinanceOption className="finance-option">
        {data.rto ? (
          <FinanceItem>
            <span className="icon">
              <AvailableIcon />
            </span>
            <span className="text">{intl.formatMessage({ id: "RTO" })}</span>
          </FinanceItem>
        ) : (
          <></>
        )}
        {data.financing ? (
          <FinanceItem>
            <span className="icon">
              <AvailableIcon />
            </span>
            <span className="text">
              {intl.formatMessage({ id: "Financing" })}
            </span>
          </FinanceItem>
        ) : (
          <></>
        )}
      </FinanceOption>
      <CardDefaultFigure className="card-img">
        <Link to={`/${intl.locale}${data.url}`}>
          {data?.image?.fluid && <Img fluid={data.image.fluid} alt={data.image.description} /> }
          <Overlay className="overlay">
            <strong>${data.startingPrice}*</strong>
          </Overlay>
        </Link>
      </CardDefaultFigure>
      <CardDefaultBody className="card-body">
        <CardDefaultTitle className="card-title">
          {data.productName}
        </CardDefaultTitle>
        <ProductSize className="measurement">
          <ProductItemSize>
            <span className="icon">
              <WidthIcon />
            </span>
            <span className="text">
              <small>{intl.formatMessage({ id: "Width" })}</small>
              <strong>{data.width}</strong>
            </span>
          </ProductItemSize>
          <ProductItemSize>
            <span className="icon">
              <LenghtIcon />
            </span>
            <span className="text">
              <small>{intl.formatMessage({ id: "Length" })}</small>
              <strong>{data.length}</strong>
            </span>
          </ProductItemSize>
          <ProductItemSize>
            <span className="icon">
              <HeightIcon />
            </span>
            <span className="text">
              <small>{intl.formatMessage({ id: "Height" })}</small>
              <strong>{data.height}</strong>
            </span>
          </ProductItemSize>
        </ProductSize>
      </CardDefaultBody>
      <CardDefaultFooter className="card-footer">
        <ButtonToolbar>
          <a href="tel:8009187432">
            <PrimaryIconButton icon={<PhoneIcon />} text="(800) 918-7432" />
          </a>
          <button
            aria-label="get a quote"
            className="btn"
            onClick={openQuoteModal}
          >
            <DefaultButton
              icon={<GetQuoteIcon />}
              text={intl.formatMessage({ id: "Get a quote" })}
            />
          </button>
        </ButtonToolbar>
        <HelpBlock>
          *
          {intl.formatMessage({
            id:
              "Price varies by states and certification requirements",
          })}
        </HelpBlock>
      </CardDefaultFooter>
    </CardDefault>
  )
}

export default ProductCard
