import React from "react"
import FormButton from "../Button/FormButton"
import { ArrowRightIcon } from "../Icons"
import axios from "axios"
import { navigate } from "gatsby"
import { FormControlLabel } from "../Section"

class RequestForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      first_name: "",
      email: "",
      mobile_no: "",
      state: "",
      zipcode: "",
      comment: "",
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }
  handleChange(event) {
    const { id, value } = event.target
    this.setState({
      [id]: value,
    })
  }
  handleSubmit(event) {
    event.preventDefault()
    const data = {
      first_name: this.state.first_name,
      email: this.state.email,
      mobile_no: this.state.mobile_no,
      state: this.state.state,
      zipcode: this.state.zipcode,
      comment: this.state.comment,
    }
    axios({
      method: "post",
      url: "https://crm.senseicrm.com/api/create-lead-wordpress",
      data: data,
      headers: { Authorization: "Bearer " + process.env.GATSBY_FORM_CRM_TOKEN },
    })
      .then(res => {
        this.setState({
          first_name: "",
          email: "",
          mobile_no: "",
          state: "",
          zipcode: "",
          comment: "",
        })
        navigate(`/${this.props.intl.locale}/thanks`)
      })
      .catch(error => {
        alert(error)
      })
  }
  render() {
    const { intl } = this.props
    return (
      <form id="requestform" onSubmit={this.handleSubmit}>
        <div className="col col-full">
          <div className="form-group">
            <input
              type="text"
              id="first_name"
              className="form-control"
              onChange={this.handleChange}
              required
            />
            <FormControlLabel
              className={
                this.state.first_name !== ""
                  ? "control-label active"
                  : "control-label"
              }
            >
              {intl.formatMessage({ id: "Full Name" })}
            </FormControlLabel>
          </div>
        </div>

        <div className="col">
          <div className="form-group">
            <input
              type="tel"
              id="mobile_no"
              className="form-control"
              onChange={this.handleChange}
              pattern="[0-9]{10}"
              required
            />
            <FormControlLabel
              className={
                this.state.mobile_no !== ""
                  ? "control-label active"
                  : "control-label"
              }
            >
              {intl.formatMessage({ id: "Mobile Number" })}
            </FormControlLabel>
          </div>
        </div>

        <div className="col">
          <div className="form-group">
            <input
              type="email"
              id="email"
              className="form-control"
              onChange={this.handleChange}
              required
            />
            <FormControlLabel
              className={
                this.state.email !== ""
                  ? "control-label active"
                  : "control-label"
              }
            >
              {intl.formatMessage({ id: "Email Address" })}
            </FormControlLabel>
          </div>
        </div>

        <div className="col">
          <div className="form-group">
            <select
              id="state"
              className="form-control"
              onBlur={this.handleChange}
            >
              <option value="">
                {intl.formatMessage({ id: "Select State" })}
              </option>
              <option value="AL">Alabama</option>
              <option value="AK">Alaska</option>
              <option value="AZ">Arizona</option>
              <option value="AR">Arkansas</option>
              <option value="CA">California</option>
              <option value="CO">Colorado</option>
              <option value="CT">Connecticut</option>
              <option value="DE">Delaware</option>
              <option value="DC">District Of Columbia</option>
              <option value="FL">Florida</option>
              <option value="GA">Georgia</option>
              <option value="HI">Hawaii</option>
              <option value="ID">Idaho</option>
              <option value="IL">Illinois</option>
              <option value="IN">Indiana</option>
              <option value="IA">Iowa</option>
              <option value="KS">Kansas</option>
              <option value="KY">Kentucky</option>
              <option value="LA">Louisiana</option>
              <option value="ME">Maine</option>
              <option value="MD">Maryland</option>
              <option value="MA">Massachusetts</option>
              <option value="MI">Michigan</option>
              <option value="MN">Minnesota</option>
              <option value="MS">Mississippi</option>
              <option value="MO">Missouri</option>
              <option value="MT">Montana</option>
              <option value="NE">Nebraska</option>
              <option value="NV">Nevada</option>
              <option value="NH">New Hampshire</option>
              <option value="NJ">New Jersey</option>
              <option value="NM">New Mexico</option>
              <option value="NY">New York</option>
              <option value="NC">North Carolina</option>
              <option value="ND">North Dakota</option>
              <option value="OH">Ohio</option>
              <option value="OK">Oklahoma</option>
              <option value="OR">Oregon</option>
              <option value="PA">Pennsylvania</option>
              <option value="RI">Rhode Island</option>
              <option value="SC">South Carolina</option>
              <option value="SD">South Dakota</option>
              <option value="TN">Tennessee</option>
              <option value="TX">Texas</option>
              <option value="UT">Utah</option>
              <option value="VT">Vermont</option>
              <option value="VA">Virginia</option>
              <option value="WA">Washington</option>
              <option value="WV">West Virginia</option>
              <option value="WI">Wisconsin</option>
              <option value="WY">Wyoming</option>
            </select>
          </div>
        </div>

        <div className="col">
          <div className="form-group">
            <input
              type="text"
              id="zipcode"
              className="form-control"
              onChange={this.handleChange}
              required
            />
            <FormControlLabel
              className={
                this.state.zipcode !== ""
                  ? "control-label active"
                  : "control-label"
              }
            >
              {intl.formatMessage({ id: "Zip Code" })}
            </FormControlLabel>
          </div>
        </div>
        <div className="col col-full">
          <div className="form-group">
            <textarea
              id="comment"
              className="form-control"
              onChange={this.handleChange}
              required
            />
            <FormControlLabel
              className={
                this.state.comment !== ""
                  ? "control-label active"
                  : "control-label"
              }
            >
              {intl.formatMessage({ id: "Ask Your Question" })}
            </FormControlLabel>
          </div>
        </div>
        <div className="form-action">
          <FormButton
            text={intl.formatMessage({ id: "Submit" })}
            icon={<ArrowRightIcon />}
          />
        </div>
      </form>
    )
  }
}

export default RequestForm
