import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { v4 } from "uuid"
import { Link } from "gatsby"
import PrimaryIconButton from "../../components/Button/PrimaryIconButton"
import { Section, SectionTitle, SectionLead } from "../../components/Section"
import { ArrowRightIcon } from "../../components/Icons"
import BreakpointUp from "../../components/Media/BreakpointUp"
import RoofStyleBg from "../../images/roof-style-bg.png"

const TimelineBox = styled.section`
  margin-top: -200px;
  position: relative;
  z-index: 1;
  padding-bottom: 60px;
  ${BreakpointUp.xl`
        padding-bottom:90px;
    `}
`
const TimelineBoxBody = styled.div`
  background-color: #fff;
  border: 1px solid #e3e3e3;
  padding: 95px 70px;
  &:after {
    ${BreakpointUp.lg`   
            display: block;
            content: "";
            background-image: linear-gradient(to top,rgba(207,207,207,0.6) 50%,rgba(255,255,255,0) 0%);
            background-position: top center;
            background-size: 2px 10px;
            background-repeat: repeat-y;
            width: 2px;
            position: absolute;
            top: 100px;
            bottom: 100px;
            left: 50%;
        `}
  }
`
const TimelineItem = styled.div`
  padding: 0;
  position: relative;
  margin-bottom: 30px;
  .timeline-img {
  }
  ${BreakpointUp.lg`   
        margin-bottom:65px;
    `}
  &:nth-child(odd) {
    .timeline {
      flex-direction: row-reverse;
    }
  }
  &:nth-child(even) {
    .timeline {
      .timeline-img {
        &:before {
          ${BreakpointUp.lg`   
                        right: auto;
                        left:-45px;
                    `}
        }
      }
      .timeline-left {
        &:before {
          left: auto;
          right: 0;
        }
        &:after {
          left: auto;
          right: -12px;
        }
      }
    }
  }
`

const Timeline = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
  ${BreakpointUp.lg`
        margin:0 -30px;
    `}
  ${BreakpointUp.xl`
        margin:0 -60px ;
    `}
`
const TimelineColumnLeft = styled.div`
  position: relative;
  width: 100%;
  padding: 0 15px;
  ${BreakpointUp.lg`
        width: 50%;
        padding:0 30px;
    `}
  ${BreakpointUp.xl`
        width: 50%;
        padding:0 60px;
    `}
     
    &:before, &:after {
    display: none;
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    ${BreakpointUp.lg`
            display: block;  
        `}
  }
  &:before {
    height: 6px;
    background-image: linear-gradient(
      to right,
      rgba(207, 207, 207, 0.5) 50%,
      rgba(255, 255, 255, 0) 0%
    );
    background-position: top center;
    background-size: 10px 2px;
    background-repeat: repeat-x;
    width: 60px;
    left: 0;
  }
  &:after {
    background-color: #fff;
    border: 1px solid #cfcfcf;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    left: -12px;
    z-index: 1;
  }
`
const TimelineColumnRight = styled.div`
  position: relative;
  width: 100%;
  padding: 0 15px;
  ${BreakpointUp.lg`
        width: 50%;
        padding:0 30px;
    `}
  ${BreakpointUp.xl`
        width: 50%;
        padding:0 60px;
    `}
`
const TimelineTitle = styled.h3`
  color: #211f28;
  margin: 0 0 5px;
`
const TimelineFigure = styled.div`
  padding-top: 50px;
  position: relative;
  &:before {
    content: "";
    position: absolute;
  }
  &:before {
    width: 198px;
    height: 198px;
    border: 20px solid #f9f9f9;
    top: 0;
    right: -45px;
  }
  & .gatsby-image-wrapper {
    height: 100%;
    position: relative;
    z-index: 1;
  }
`
const TimelineBody = styled.div`
  position: relative;
`
const TimelineContent = styled.div`
  padding: 20px 0 0 0;
  ${BreakpointUp.md`    
    padding:50px 0 0;
  `}
`
const TimelineText = styled.div`
  p {
    margin-bottom: 20px;
  }
`
const ButtonToolbar = styled.div`
  position: relative;
`

const ProductRoofStyleSection = ({ intl, data }) => {
  return (
    <>
      <Section
        pt="90px"
        pb="200px"
        xpt="60px"
        xpb="160"
        bgColor="#0F0F11"
        bgImg={`url(${RoofStyleBg})`}
      >
        <SectionTitle textAlign="center" color="#fff">
          {data.title}
        </SectionTitle>
        <SectionLead
          textAlign="center"
          color="#fff"
          maxWidth="1120px"
          mb="60px"
        >
          <div
            dangerouslySetInnerHTML={{
              __html: data.description.childMarkdownRemark.html,
            }}
          />
        </SectionLead>
      </Section>
      <TimelineBox>
        <div className="container">
          <TimelineBoxBody>
            {data.roofStyles.map(item => {
              return (
                <TimelineItem key={v4()}>
                  <Timeline className="timeline">
                    <TimelineColumnLeft className="timeline-left">
                      <TimelineFigure className="timeline-img">
                        <Img
                          fluid={item.image.fluid}
                          alt={item.image.description}
                        />
                      </TimelineFigure>
                    </TimelineColumnLeft>
                    <TimelineColumnRight className="timeline-right">
                      <TimelineBody className="timeline-body">
                        <TimelineContent>
                          <TimelineTitle>{item.title}</TimelineTitle>
                          <TimelineText>
                            <div
                              dangerouslySetInnerHTML={{
                                __html:
                                  item.description.childMarkdownRemark.html,
                              }}
                            />
                          </TimelineText>
                          <ButtonToolbar>
                            {item.buttonText ? (
                              <Link to={`/${intl.locale}${item.buttonLink}`}>
                                <PrimaryIconButton
                                  icon={<ArrowRightIcon />}
                                  text={item.buttonText}
                                />
                              </Link>
                            ) : (
                              <></>
                            )}
                          </ButtonToolbar>
                        </TimelineContent>
                      </TimelineBody>
                    </TimelineColumnRight>
                  </Timeline>
                </TimelineItem>
              )
            })}
          </TimelineBoxBody>
        </div>
      </TimelineBox>
    </>
  )
}

export default ProductRoofStyleSection
