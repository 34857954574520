import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { Section } from "../Section"
import BreakpointUp from "../Media/BreakpointUp"
import Image from "../image"

const BackgroundImage = styled.div`
  display: block;
  width: 68%;
  position: absolute;
  top: 10%;
  right: 0;
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
`
const TopHeaderSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  align-items: center;
  margin: 0 -15px;
  ${BreakpointUp.md`
    text-align: left;
  `}
  ${BreakpointUp.xl`
    margin:0 -30px;
  `}
`
const CardColumn = styled.div`
  position: relative;
  width: 100%;
  padding: 0 15px;
  ${BreakpointUp.lg`
    width: 50%;
  `}
  ${BreakpointUp.xl`
    width: 50%;
    padding:0 30px;
  `}
`

const TopHeaderTitle = styled.h1`
  margin: 0 0 5px;
`
const TopHeaderFigure = styled.div`
  position: relative;
  padding-top: 90px;

  .gatsby-image-wrapper {
    position: relative;
    width: 100%;
    z-index: 1;
  }
  &:before,
  &:after {
    content: "";
    position: absolute;
    transform: matrix(0.87, 0.5, -0.5, 0.87, 0, 0);
  }
  &:before {
    width: 83px;
    height: 83px;
    border: 8px solid #f9f9f9;
    top: 32px;
    right: 35px;
  }
  &:after {
    width: 50px;
    height: 50px;
    border: 5px solid #211f28;
    top: 0;
    right: 115px;
  }
`
const TopHeaderBody = styled.div`
  position: relative;
  padding: 0;
  ${BreakpointUp.md`    
    padding: 30px 30px 0 0;
  `}
  &:after {
    content: "";
    width: 42px;
    height: 42px;
    border: 4px solid #211f28;
    transform: matrix(0.87, 0.5, -0.5, 0.87, 0, 0);
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
    display: none;
    ${BreakpointUp.md`    
      display:block;
    `}
  }
`
const TopHeaderContent = styled.div`
  position: relative;
  z-index: 1;
  padding: 20px 0 30px 0;
  ${BreakpointUp.md`    
    padding:65px 0 65px;      
  `}
`
const TopHeaderText = styled.div`
  p {
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 0;
    + p {
      margin-top: 20px;
    }
    ${BreakpointUp.lg`
      font-size: 18px;
      line-height: 30px;
    `}
  }
  & a {
    color: #6a6a6a;
    font-weight: 600;
    &:hover,
    &:focus {
      color: #000;
    }
  }
`
const TopHeader = props => {
  const { topHeaderTitle, topHeaderText, topHeaderImg } = props
  return (
    <Section
      pt="200px"
      pb="90px"
      xpt="70px"
      xpb="60px"
      bgColor="#F6F6F6"
      bdrBottom="#D8D8D8"
    >
      <BackgroundImage>
        <Image name="banner-bg.png" alt="banner-bg" />
      </BackgroundImage>
      <div className="container">
        <TopHeaderSection>
          <CardColumn>
            <TopHeaderBody>
              <TopHeaderContent>
                <TopHeaderTitle>{topHeaderTitle}</TopHeaderTitle>
                <TopHeaderText>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: topHeaderText.childMarkdownRemark.html,
                    }}
                  />
                </TopHeaderText>
              </TopHeaderContent>
            </TopHeaderBody>
          </CardColumn>
          <CardColumn>
            <TopHeaderFigure>
              <Img fluid={topHeaderImg.fluid} alt={topHeaderImg.description} />
            </TopHeaderFigure>
          </CardColumn>
        </TopHeaderSection>
      </div>
    </Section>
  )
}

export default TopHeader
