import React from "react"
import styled from "styled-components"
import { v4 } from 'uuid'
import ProductCard from "../ProductCard"
import BreakpointUp from "../Media/BreakpointUp"


const SectionGrid = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
`
const SectionItem = styled.div`
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  ${BreakpointUp.lg`
    flex: 0 0 50%;
    max-width: 50%;
  `}
`
const ProductCardList = ({ intl, data, openQuoteModal }) => {
  return (
    <div>   
      <SectionGrid>
        {
          data.map(item => (
            <SectionItem key={v4()}>
              <ProductCard data={item.node} intl={intl} openQuoteModal={openQuoteModal} />
            </SectionItem>
          ))
        }
      </SectionGrid>
    </div>
  )
}

export default ProductCardList
